import Login from "@components/login/containers/app";
import { Box } from "@mui/material";
import type { RootState } from "@stores/store";
import { initAppData } from "@utils/initAppData";
import Layout from "layout/landingPage";
import Head from "next/head";
import Router from "next/router";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const app = useSelector((store: RootState) => store.app);

  const successCallBack = (data) => {
    const redirectUrl = data.redirect;

    if (!redirectUrl) {
      Router.push("/dashboard/home");
    } else {
      if (redirectUrl.substr(0, 4) === "http") {
        window.location.assign(decodeURIComponent(redirectUrl));
      } else {
        Router.push(redirectUrl);
      }
    }
  };

  return (
    <Layout app={app} defaultSeo={false}>
      <Head>
        <title>Sign In | Printy6</title>
      </Head>
      <Box
        sx={{
          maxWidth: 480,
          mx: "auto",
          mt: 4,
        }}
      >
        <Login successCallBack={successCallBack} initType="signin" />
      </Box>
    </Layout>
  );
};

LoginPage.getInitialProps = async (ctx) => {
  const response = await initAppData(ctx);

  if (response.user) {
    if (ctx.res) {
      ctx.res.writeHead(302, {
        Location: "/dashboard/home",
      });
      ctx.res.end();
    } else {
      Router.push("/dashboard/home");
    }
  }

  return {};
};

export default LoginPage;
